<template>
<header class="ColorSkin">
	<div class="Container">
		<div class="Box">
			<div class="Logo"><a href="/"><img src="images/Logo.png" srcset="images/LogoRetina.png 2x" alt="Studio Sport"></a></div>
		</div>
	</div>
</header>
	<section class="SigninSection">
		<div class="Container">
			<div class="Box">
				<h1>Grazie!</h1>
				<h2>Il tuo ordine {{Transazione.codTrans}} è stato ricevuto</h2>
				<div class="TxtEditor">
					<p>A breve riceverà una mail contenente il riepilogo del suo ordine.</p>
				</div>
				<div class="Button" style="margin-top:20px;">
					<a href="#/">Torna alla home page</a>						
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import OrderService from "@/services/order.service.js";
import OrderData from "@/models/OrderData.model.js";
export default {
	name: "ThankyouTeam",
    data(){
        return {
			Transazione: [],
        }
    },
	methods: {

		InserimentoOrdine : function() {
			let NoteOrdine = " #### Note Utente: " + (this.$store.getters.getNoteOrdine ?? " ").slice(0,200);
            let orderData = new OrderData(
				NoteOrdine,
                this.$store.getters.getTestata.IdCarrello,
				this.$store.getters.getK2AnagenIndir,
				null,
                this.$store.getters.getSpedizione,
				this.$store.getters.getFatturazione,				
				this.$store.getters.getCostoSpedizione
            );
			
            OrderService.sendOrder(this.$store.getters.getToken, this.$store.getters.getShop, orderData).then(
                /** @type {OrderResponse} */
                (data) => {
                        this.$store.commit("removeCart");
                }
            )
            .catch(
                error => {
                }
            );      
		}
	},

	mounted: function(){
            this.InserimentoOrdine();
    }
    
}
</script>
